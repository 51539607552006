import { getServerRequest } from '../../helpers/serverRequest';
import { store } from '../../store/store';

const httpURL = store.getters.httpURL;

export async function getSesionesAbiertasService() {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/sesiones/abiertas`;
    return await getServerRequest(url, config);
}

export async function getSesionService(idSesion) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/sesion/${idSesion}`;
    return await getServerRequest(url, config);
}

export async function cargarInvitacionSesion(idSesion) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/sesion/invitacion/${idSesion}`;
    return await getServerRequest(url, config);
}

export async function cargarEnlaceSesion(idSesion) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/sesion/enlace/${idSesion}`;
    return await getServerRequest(url, config);
}
