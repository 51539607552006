<template>
  <v-container>
    <v-row v-if="loading">
      <v-col md="6" v-for="number in 2" :key="'loader-' + number">
        <v-skeleton-loader
          class="mx-auto"
          type="card"
          height="150"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-card v-else flat>
      <v-card outlined>
        <v-list>
          <template v-for="sesion in sesionesLocal">
            <v-list-item :key="`sesion-${sesion._id}`">
              <v-list-item-content>
                <v-list-item-title class="text-h6">
                  <router-link :to="`/sesion/${sesion._id}`" class="link">
                    {{ sesion.nombre }}
                  </router-link>
                </v-list-item-title>
                <v-list-item-subtitle>{{ sesion.fecha }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{
                  sesion.consejo
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </v-card>
    </v-card>
  </v-container>
</template>

<script>
import { DateTime } from "luxon";
import { mapGetters } from "vuex";

import { getSesionesAbiertasService } from "./sesionesLive.service";

export default {
  name: "sesiones",
  computed: {
    ...mapGetters(["userAdmin"]),
    sesionesLocal() {
      const sesiones = [];
      for (const sesion of this.sesiones) {
        var fechaSesion = DateTime.fromISO(sesion.fecha);
        const sesionItem = {
          _id: sesion._id,
          nombre: sesion.nombre,
          consejo: sesion.consejo.nombre,
          fecha: fechaSesion.toLocaleString(DateTime.DATETIME_MED),
        };
        sesiones.push(sesionItem);
      }
      return sesiones;
    },
  },
  data() {
    return {
      loading: false,
      sesiones: [],
    };
  },
  mounted() {
    this.cargarSesiones();
  },
  methods: {
    async cargarSesiones() {
      try {
        this.loading = true;
        const serverResponse = await getSesionesAbiertasService(this.idSesion);
        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok) {
          throw new Error(serverResponse.mensaje);
        } else {
          this.sesiones = serverResponse.sesiones;
        }
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
  },
};
</script>